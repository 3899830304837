// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$beige: #f3eace;
$light-green: #e1e59f;
$green-btn: #c3ca3f;
$light-red: #f6a2a2;
$super-light-red: #f7dfdd;
$super-light-green: #f5f6df;
$red-text: #290001;
$green-text: #5e6019;
$brown-text: #301e07;
$light-yellow: #fee156;

.brown-text {
    color: $brown-text;
}
.text-green {
    color: $green-text;
}
.btn-green {
    background-color: $green-btn;
    color: #000;
}
.bg-green {
    background-color: $green-btn;
}
.bg-light-green {
    background-color: $light-green;
}
.bg-super-light-green {
    background-color: $super-light-green;
}
.bg-light-yellow {
    background-color: $light-yellow;
}