// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

p {
    font-size: 20px;
}
body {
    background-color: $beige !important;
}

.category-list {
    .card {
        background-color: white
    }
}
.ais-InstantSearch,.ais-SearchBox, .search-container > div {
    width: 485px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
nav svg {
    height: 40px;
}


